import axios from 'axios';
import React, { useState, createContext, useContext,useEffect } from 'react';
import Modal from './Modal'
import { debounce } from '../../Helpers/util'
import PropTypes from 'prop-types';

const PAGE_RESULT_LIMIT = 100;

const apiCourses = ({gameId = null, search=null, skip=0, limit=PAGE_RESULT_LIMIT}) => {
    let query = `/courses/?skip=${skip}&limit=${limit}`;
    if(gameId){
        query += `&game_id=${gameId}`
    }
    if(search){
        query += `&search=${search}`
    }
    return axios.get(query);
}

const CourseContext = createContext();

export default function ChapterForm() {
    const [courses, setCourses] = useState([]);
    const [gameId, setGameId] = useState(null);
    const [search, setSearch] = useState(null);
    const [skip, setSkip] = useState(0);
    const [alertText, setAlert] = useState(null)
    const [formVisible, setToggleForm] = useState({
        add: false,
        show: false,
    })

    const setData = (evt) => {
        const { name, value } = evt.target;
        switch (name) {
            case 'gameId':
                setGameId(value)
                break;
            case 'search':
                setSearch(value)
                break;
            default:
                throw new Error(`name: ${name} does not exist on this state`)
        }
    } 

    const fetchCourses = () => {
        return apiCourses({gameId,search,skip})
            .then(res => {
                const { status, data } = res;
                if(status === 200){
                    setCourses(data)
                }else{
                    throw new Error(`Unknown status code response from server: ${status}`)
                }
            }).catch(()=>alert("Jotain meni pieleen datan hakemisessa"));
    };
    // if query params change reset pagination (skip)
    useEffect(()=>{
        setSkip(0)
    },[search,gameId])

    useEffect(()=>{
        //FIXME use abort controller to handle the unmounting of this object
        fetchCourses()
    },[gameId,skip,search])

    const nextPage = () => {
        if(courses.length >= PAGE_RESULT_LIMIT){
            const newSkip = skip+PAGE_RESULT_LIMIT
            setSkip(newSkip);
        }
    };

    const lastPage = () => {
        if(skip > 0){   
            // skip can't be a negative value
            const newSkip = (skip-PAGE_RESULT_LIMIT) < 0 ? 0 : (skip-PAGE_RESULT_LIMIT); 
            setSkip(newSkip);
        }
    };

    return (
        <CourseContext.Provider
            value={{
                courses,
                fetchCourses,
                setToggleForm,
                skip,
                lastPage,
                nextPage,
                setGameId
            }}>
            <div className='form-comp'>
                <h2 className='controlpanel-h2'>Moduulin hallinta</h2>

                <div id='course-res' className='response-text' style={{display: 'none'}}></div>

                <button className='open-but' onClick={()=> {
                    setToggleForm({add: true})
                }}>Lisää moduuli</button>
                {
                    formVisible.add && (
                        <AddCourse />
                    )
                }
   
                <button className='open-but' onClick={()=> {
                    setToggleForm({show: true})
                }}>Hae</button>
                {
                    formVisible.show && (
                        <div id='getchapter' className='chapterform'>
                            <input 
                                type="number" 
                                className='form-child' 
                                placeholder='Pelin ID'
                                name='gameId'
                                {...(gameId !== null && {value: gameId})}     
                                onChange={setData}
                                />

                            <input 
                                type="text" 
                                className='form-child' 
                                placeholder='Hae otsikon perusteella'
                                name='search'
                                onChange={debounce(setData,300)}
                                />

                            <CourseList />
                        </div>
                    )
                }
            </div>
        </CourseContext.Provider>
    );
}

const CourseList = () => {
    const { courses, skip, lastPage, nextPage } = useContext(CourseContext)


    const Pagination = () => (
        <div className='between'>
            <button 
                style={{visibility: skip > 0 ? "visible" : "hidden"}}  
                className="link-button"
                onClick={lastPage}>
                Edellinen sivu
            </button>
            <button
                style={{visibility: courses.length >= PAGE_RESULT_LIMIT ? "visible" : "hidden"}}  
                className="link-button"
                onClick={nextPage}>
                Seuraava sivu
            </button>
        </div>
    );

    return (
        <ul className="list">
            <Pagination />

            {
                courses.length > 0 ? courses.map(course => <CourseItem key={course.id + "course-item"} course={course} />) : <p> Moduuleja ei löytynyt </p>
            }

            <Pagination />
        </ul>
    )
}

const CourseItem = ({course: ogCourse}) => {
    const { fetchCourses } = useContext(CourseContext);
    const [course, setCourse] = useState(ogCourse)
    const [open, setOpen] = useState(false);

    const onDeleteClick = (id) => {
        // eslint-disable-next-line no-restricted-globals
        if(confirm("Oletko varma, että haluat poistaa moduulin?")){
            axios.delete(`/courses/${id}`)
                .then(res => {
                    if(res.status === 200 ){
                        fetchCourses()
                    }else{
                        throw new Error(`Unexpected status code in a delete response: ${res.status}`)
                    }
                }).catch(() => alert("Jotain meni pieleen yritä myöhemmin uudelleen!"))
        }
    }
  
    const patchChapter = (course) => {
        axios.patch(`/courses/${course.id}/`,course)
            .then((res) => {
                const { status, data } = res;
                if(status === 200){
                    setCourse(data);
                    setOpen(false)
                }else{
                    throw new Error(`Unknown status code from server: ${status}`)
                }
            }).catch(err => {
                alert("Jotain meni pieleen yritä myöhemmin uudelleen")
            });      
    };
    
    return (
        <li className='list-child'> 
            ID: {course.id} | {course.title}
            <br />
            Pelin ID: {course.game_id}
            <div className='between'>
                <button className="link-button" onClick={() => setOpen(true)}> 
                    Muokkaa 
                </button> 
                <button 
                    className="link-button" 
                    style={{color: "red"}} 
                    onClick={() => onDeleteClick(course.id)}> 
                    Poista 
                </button>
            </div>

            <Modal open={open} toggle={() => setOpen(!open)} header="Muokkaa lukua">
                <CourseField onSave={patchChapter} course={course}></CourseField>
            </Modal>
        </li>
    )
}

const AddCourse = () => {
    const { fetchCourses, setToggleForm, setGameId } = useContext(CourseContext)
    const postCourse = course => {
        axios.post("/courses/", course)
            .then(res => {
                const { status } = res;
                if(status === 200 || status === 201){
                    setGameId(course.game_id)
                    setToggleForm({add: false, show:true })
                    fetchCourses()              
                }else{
                    throw new Error(`Unknown status code from post: ${status}`)
                }
            }).catch(() => alert("Jotain meni pieleen yritä myöhemmin uudelleen"));
    } 


    return <CourseField onSave={postCourse} ></CourseField>
}

const newChapterObj = { 
    game_id: 0, 
    title: "", 
    description: ""
};

const CourseField = ({onSave, course: parentChapter = newChapterObj}) => {
    const [course, setCourse] = useState(parentChapter)

    const setData = evt => {
        const { name, value } = evt.target;
        if(Object.keys(course).includes(name)){
            setCourse({
                ...course,
                [name]:  value
            })
        }else{
            throw new Error(`Input ${name} is not included in the course data`)
        }
    }

    return(
        <div id='addquestion' className='questionform'>
            <p>Tähdellä (*)-merkityt kohdat ovat pakollisia</p>
            <input 
                onChange={setData} 
                value={course.title} 
                name="title" 
                type="text" 
                className='form-child' 
                placeholder='Moduulin otsikko *'/>
            <input 
                onChange={setData} 
                value={course.description} 
                name="description" 
                type="text" 
                className='form-child' 
                placeholder='Moduulin leipäteksti *'/>
            <input 
                onChange={setData}  
                // inline magic to display the placeholder when chapter_id hasn't been properly defined
                {...(course.game_id !== 0 && {value: course.game_id})}     
                name="game_id" 
                type="number" 
                className='form-child' 
                placeholder='Pelin ID*'/>

            <button className='sub-but' onClick={() => onSave(course)}>Tallenna</button>
        </div>
    );
};

CourseField.propTypes = {
    course: PropTypes.shape({
        title: PropTypes.string.isRequired,
        game_id: PropTypes.number.isRequired,
        description: PropTypes.string
    }),
    onSave: PropTypes.func.isRequired
};