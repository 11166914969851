import axios from 'axios';
import React, { useState, createContext, useContext,useEffect } from 'react';
import Modal from './Modal'
import { debounce } from '../../Helpers/util'
import PropTypes from 'prop-types';

const PAGE_RESULT_LIMIT = 100;

const apiChapters = ({courseId = null,gameId = null, search=null, skip=0, limit=PAGE_RESULT_LIMIT}) => {
    let query = `/chapters/?skip=${skip}&limit=${limit}`;
    if(courseId){
        query += `&course_id=${courseId}`
    }
    if(gameId){
        query += `&game_id=${gameId}`
    }
    if(search){
        query += `&search=${search}`
    }
    return axios.get(query);
}

const ChapterContext = createContext();

export default function ChapterForm() {
    const [chapters, setChapters] = useState([]);
    const [courseId, setCourseId] = useState(null);
    const [gameId, setGameId] = useState(null);
    const [search, setSearch] = useState(null);
    const [skip, setSkip] = useState(0);
    const [alertText, setAlert] = useState(null)
    const [formVisible, setToggleForm] = useState({
        add: false,
        show: false,
        delete: false  
    })
    let timer;
    const setData = (evt) => {
        const { name, value } = evt.target;
        switch (name) {
            case 'courseId':
                setCourseId(value)
                break;
            case 'gameId':
                setGameId(value)
                break;
            case 'search':
                setSearch(value)
                break;
            default:
                throw new Error(`name: ${name} does not exist on this state`)
        }
    } 

    const fetchChapters = () => {
        return apiChapters({gameId, courseId, search, skip}).then(res => {
            const { data, status } = res;
            if(status === 200){
                setChapters(data);
            }
        });
    };
    // if query params change reset pagination (skip)
    useEffect(()=>{
        setSkip(0)
    },[courseId,search,gameId])

    useEffect(()=>{
        //FIXME use abort controller to handle the unmounting of this object
        fetchChapters()
    },[courseId,gameId,skip,search])

    const nextPage = () => {
        if(chapters.length >= PAGE_RESULT_LIMIT){
            const newSkip = skip+PAGE_RESULT_LIMIT
            setSkip(newSkip);
        }
    };

    const lastPage = () => {
        if(skip > 0){
            // skip can't be a negative value
            const newSkip = (skip-PAGE_RESULT_LIMIT) < 0 ? 0 : (skip-PAGE_RESULT_LIMIT); 
            setSkip(newSkip);
        }
    };

    return (
        <ChapterContext.Provider
            value={{
                chapters,
                fetchChapters,
                setToggleForm,
                setCourseId,
                skip,
                lastPage,
                nextPage
            }}>
            <div className='form-comp'>
                <h2 className='controlpanel-h2'>Lukujen hallinta</h2>

                <div id='chapter-res' className='response-text' style={{display: 'none'}}></div>

                <button className='open-but' onClick={()=> {
                    setToggleForm({add: true})
                }}>Lisää luku</button>
                {
                    formVisible.add && (
                        <AddChapter />
                    )
                }
   
                <button className='open-but' onClick={()=> {
                    setToggleForm({show: true})
                }}>Hae luvut</button>
                {
                    formVisible.show && (
                        <div id='getchapter' className='chapterform'>
                            <input 
                                type="number" 
                                className='form-child' 
                                placeholder='Moduulin ID'
                                name='courseId'
                                {...(courseId !== null && {value: courseId})}     
                                onChange={setData}
                                />

                            <input 
                                type="number" 
                                className='form-child' 
                                placeholder='Pelin ID'
                                name='gameId'
                                onChange={setData}
                                />

                            <input 
                                type="text" 
                                className='form-child' 
                                placeholder='Hae otsikon perusteella'
                                name='search'
                                onChange={debounce(setData,300)}
                                />

                            <ChapterList />
                        </div>
                    )
                }
            </div>
        </ChapterContext.Provider>
    );
}

const ChapterList = () => {
    const { chapters, skip, lastPage, nextPage } = useContext(ChapterContext)


    const Pagination = () => (
        <div className='between'>
            <button 
                style={{visibility: skip > 0 ? "visible" : "hidden"}}  
                className="link-button"
                onClick={lastPage}>
                Edellinen sivu
            </button>
            <button
                style={{visibility: chapters.length >= PAGE_RESULT_LIMIT ? "visible" : "hidden"}}  
                className="link-button"
                onClick={nextPage}>
                Seuraava sivu
            </button>
        </div>
    );

    return (
        <ul className="list">
            <Pagination />

            {
                chapters.length > 0 ? chapters.map(chapter => <ChapterItem key={chapter.id + "chp-item"} chapter={chapter} />) : <p> Lukuja ei löytynyt </p>
            }

            <Pagination />
        </ul>
    )
}

const ChapterItem = ({chapter: ogChapter}) => {
    const { fetchChapters } = useContext(ChapterContext);
    const [chapter, setChapter] = useState(ogChapter)
    const [open, setOpen] = useState(false);

    const onDeleteClick = (id) => {
        // eslint-disable-next-line no-restricted-globals
        if(confirm("Oletko varma, että haluat poistaa luvun?")){
            axios.delete(`/chapters/${id}`)
                .then(res => {
                    if(res.status === 200 ){
                        fetchChapters()
                    }else{
                        throw new Error(`Unexpected status code in a delete response: ${res.status}`)
                    }
                }).catch(() => alert("Jotain meni pieleen yritä myöhemmin uudelleen!"))
        }
    }
  
    const patchChapter = (chapter) => {
        axios.patch(`/chapters/${chapter.id}/`,chapter)
            .then((res) => {
                const { status, data } = res;
                if(status === 200){
                    setChapter(data);
                    setOpen(false)
                }else{
                    throw new Error(`Unknown status code from server: ${status}`)
                }
            }).catch(err => {
                alert("Jotain meni pieleen yritä myöhemmin uudelleen")
            });      
    };
    
    return (
        <li className='list-child'> 
            ID: {chapter.id} | {chapter.title}
            <br />
            Moduulin ID: {chapter.course_id}
            <div className='between'>
                <button className="link-button" onClick={() => setOpen(true)}> 
                    Muokkaa 
                </button> 
                <button 
                    className="link-button" 
                    style={{color: "red"}} 
                    onClick={() => onDeleteClick(chapter.id)}> 
                    Poista 
                </button>
            </div>

            <Modal open={open} toggle={() => setOpen(!open)} header="Muokkaa lukua">
                <ChapterField onSave={patchChapter} chapter={chapter}></ChapterField>
            </Modal>
        </li>
    )
}

const AddChapter = () => {
    const { fetchChapters, setToggleForm, setCourseId } = useContext(ChapterContext)
    const postChapter = chapter => {
        axios.post("/chapters/", chapter)
            .then(res => {
                const { status } = res;
                if(status === 200 || status === 201){
                    setCourseId(chapter.course_id)
                    setToggleForm({add: false, show:true })
                    fetchChapters({courseId: chapter.course_id})              
                }else{
                    throw new Error(`Unknown status code from post: ${status}`)
                }
            }).catch(() => alert("Jotain meni pieleen yritä myöhemmin uudelleen"));
    } 


    return <ChapterField onSave={postChapter} ></ChapterField>
}

const newChapterObj = { 
    course_id: 0, 
    title: "", 
    description: ""
};

const ChapterField = ({onSave, chapter: parentChapter = newChapterObj}) => {
    const [chapter, setChapter] = useState(parentChapter)

    const setData = evt => {
        const { name, value } = evt.target;
        if(Object.keys(chapter).includes(name)){
            setChapter({
                ...chapter,
                [name]:  value
            })
        }else{
            throw new Error(`Input ${name} is not included in the chapter data`)
        }
    }

    return(
        <div id='addquestion' className='questionform'>
            <p>Tähdellä (*)-merkityt kohdat ovat pakollisia</p>
            <input 
                onChange={setData} 
                value={chapter.title} 
                name="title" 
                type="text" 
                className='form-child' 
                placeholder='Luvun otsikko *'/>
            <input 
                onChange={setData} 
                value={chapter.description} 
                name="description" 
                type="text" 
                className='form-child' 
                placeholder='Luvun leipäteksti *'/>
            <input 
                onChange={setData}  
                // inline magic to display the placeholder when chapter_id hasn't been properly defined
                {...(chapter.course_id !== 0 && {value: chapter.course_id})}     
                name="course_id" 
                type="number" 
                className='form-child' 
                placeholder='Moduulin ID*'/>

            <button className='sub-but' onClick={() => onSave(chapter)}>Tallenna</button>
        </div>
    );
};

ChapterField.propTypes = {
    chapter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        course_id: PropTypes.number.isRequired,
        description: PropTypes.string
    }),
    onSave: PropTypes.func.isRequired
};