/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect} from 'react';
import { QuizContext } from '../Helpers/Contexts';
import "../App.css";
import { useParams, useNavigate } from 'react-router-dom';

function EndScreen() {
    const navigate = useNavigate();

    const { score, setScore, setGameState, gameMode, course, questions, setGameLength, currChapter, setGameSession } = useContext(QuizContext);

    const { gameId, courseId, chapterId } = useParams();

    // set redirect urls 
    let invalidStateUrl, backUrl; 
    if (courseId && chapterId) {
        invalidStateUrl = `/game/${gameId}/chapters/${courseId}/gamemode/${chapterId}/gamelength`; 
        backUrl = `/game/${gameId}/chapters/${courseId}`;
    }else if (courseId && !chapterId) {
        invalidStateUrl = `/game/${gameId}/chapters/${courseId}`;
        backUrl = `/game/${gameId}/chapters/${courseId}`;
    }else{
        invalidStateUrl = `/game/${gameId}`;
        backUrl = `/game/${gameId}`;
    }

    useEffect(() => {
        // Redirect if users lands on this page without playing a game
        if(!questions || !course || typeof score !== 'number' || !currChapter){
            navigate(invalidStateUrl);
            return null;
        }    
    },[questions, course, score,currChapter]);
    
    const RestartQuiz = () => {
        setGameLength(0);
        setScore(0);
        setGameSession(undefined);
        setGameState("gameModeMenu");
        navigate(backUrl);
    }

    const CalcPercent = () => {
        if(score === 0) return 0;
        let perc = (score/(questions.length*2))*100;
        perc = perc.toFixed();
        if (perc < 0) return 0;
        return perc;
    }
    if( course && currChapter && questions && typeof score === 'number'){
        return (
            <div className="component">
            {gameMode === "linear" ? (
                <>
                    <h2 className='stat-header'>Moduulin {course.title} osa {currChapter && currChapter.title && currChapter.title.toLowerCase()}</h2>
                    <h3 className="stat">Pisteet: {score} / {questions.length*2}</h3>
                    <p className="stat">Sait {CalcPercent()}% testin pisteistä</p>
                    <button className="stat" id="restart" onClick={() => RestartQuiz()}>Takaisin valikkoon</button>
                </>
                
            ) : (
                <>
                    <h2 className='stat-header'>Moduulin {course.title} osa {currChapter && currChapter.title && currChapter.title.toLowerCase()}</h2>
                    <h3 className="stat">Pisteet: {score}</h3>
                    <button className="stat" id="restart" onClick={() => RestartQuiz()}>Takaisin valikkoon</button>
                </>
            )} 
            </div>
        );
    }else{
        return null;
    }
}

export default EndScreen;