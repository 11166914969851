/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { QuizContext } from '../Helpers/Contexts';
import "../App.css";
import Info from './Info';
import { Link, useParams } from 'react-router-dom';
import { useChapterData } from '../hooks/api.hooks';
import { GameMode } from '../Helpers/util';
import HomeButton from './HomeButton';

export default function Chapters() {
    const { setGameState, setCourse, setCurrChapter, setGameSession, setScore, setGameLength } = useContext(QuizContext)
    const { courseId, gameId } = useParams();

    useEffect(() => {
        setGameSession(undefined);
        setScore(0);
        setGameLength(0);
    },[]);   
    
    const chapterData = useChapterData(courseId);
    // TODO fetchCourseIfNeeded
    const chapters = chapterData.map((chapterData) =>
        <Link to={`gamemode/${chapterData.id}`} key={chapterData.id+chapterData.title+chapterData.course_id}>
            <button className='menubtn' onClick={() => {
                setCurrChapter({id: chapterData.id, title: chapterData.title});
                setGameState("gameModeMenu");
            }}>{chapterData.title}</button>
        </Link> 
    );
    return (
        <div className="component">
            <HomeButton gid={gameId}/>
            <Info/>
            <h3>Valitse pelattava osa moduulista</h3>
            <Link to={`gamemode/${GameMode.ALL.TYPE}`}>
                <button className='menubtn' onClick={() => {
                    setCurrChapter({id:0,title:'Kaikki'});
                    setGameState("gameModeMenu");
                }}>Koko moduuli</button>
            </Link>
            {chapters}
            <Link to={`/game/${gameId}`}>
                <button className="back" onClick={() => {
                    setCourse([]);
                    setGameState("course");
                }}>Takaisin</button>
            </Link>
        </div>
    );
}