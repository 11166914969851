import "./App.css";
import React from "react";
import Courses from "./Components/Courses";
import { useParams } from "react-router-dom";
import { useCourseData, useGameData } from "./hooks/api.hooks";

export default function Game() {
  const { id: gameId } = useParams();
  useGameData(gameId)
  useCourseData(gameId) 
  // this should could be deleted?
  return <Courses />;
}
