import './ControlpanelApp.css';
import React, {useState,useContext} from "react";
import GameForm from './Components/GameForm';
import ModuleForm from './Components/ModuleForm';
import ChapterForm from './Components/ChapterForm';
import QuestionForm from './Components/QuestionForm';
import { QuizContext } from '../Helpers/Contexts';

export default function ControlpanelApp() {
  const [view, setView] = useState('game');
  const { token } = useContext(QuizContext)
  const Nav = () => {
    return (
      <div className="nav">
        <h1 id='controlpanel-header'>Harjoituspelien hallinta</h1>
        <div className="btn-cont">
          <button className="nav-but" onClick={() => {
            setView('game')
          }}>Peli</button>
          <button className="nav-but" onClick={() => {
            setView('module')
          }}>Moduuli</button>
          <button className="nav-but" onClick={() => {
            setView('chapter')
          }}>Luku</button>
          <button className="nav-but" onClick={() => {
            setView('question')
          }}>Kysymys</button>
        </div>
      </div>
    );
  }
  if(token){
    return (
      <div className="ControlpanelApp">
        <Nav/>
        {view === 'game' && (
          <GameForm/>
        )}
        {view === 'module' && (
          <ModuleForm/>
        )}
        {view === 'chapter' && (
          <ChapterForm/>
        )}
        {view === 'question' && (
          <QuestionForm/>
        )}
      </div>
    );
  }else{
    return <p> Ladataan </p>
  }
}