/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext } from 'react';
import { QuizContext } from '../Helpers/Contexts';
import "../App.css";
import Info from './Info';
import { Link, useParams } from 'react-router-dom';
import { useCurrChapter, useStartGameSession } from '../hooks/api.hooks';
import { GameMode } from '../Helpers/util';
import HomeButton from './HomeButton';

export default function GameModeMenu({chapterModeType, courseModeType}) {
    const {setGameState, setGameMode, course } = useContext(QuizContext)
    // chapterId used as a GameModeType is kinda funky, this should be refactored
    const { gameId, chapterId, courseId } = useParams();
    const gameModeType = chapterModeType || courseModeType;
    const currChapter = useCurrChapter(chapterId, gameModeType);
    const startGameSession = useStartGameSession(chapterModeType, courseModeType);

    return (
        <div className="component">
            <HomeButton gid={gameId}/>
            <h3>Valitse pelimuoto</h3>
            <Info/>
            <Link to="gamelength">
                <button className='menubtn' onClick={() => {
                    setGameState("gameLength");
                    setGameMode("linear");
                }}>Valitse kysymysten määrä</button>
            </Link>
            <Link to="quiz">
                <button className='menubtn' onClick={async () => {
                    try{
                        await startGameSession();

                        setGameState("quiz");
                        setGameMode("endless");
                    }catch{
                        alert("Jotain meni pieleen, yritä pelata peliä myöhemmin!")
                    }
                }}>Loputon peli</button>
            </Link>
            {course.title === GameMode.ALL.TITLE || course.title === GameMode.IMPORTANT.TITLE ? (
                <Link to={`/game/${gameId}`}>
                    <button className="back" onClick={() => {
                        setGameState("course")
                    }}>Takaisin</button>
                </Link>
            ):(
                <Link to={`/game/${gameId}`}>
                    <button className="back" onClick={() => {
                        setGameState("chapter")
                    }}>Takaisin</button>
                </Link>
            )}
        </div>
    );
}


GameModeMenu.propTypes = GameMode.propTypes;
GameModeMenu.defaultProps = GameMode.defaultProps;