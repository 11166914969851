/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { QuizContext } from '../Helpers/Contexts';
import "../App.css";
import Info from './Info';
import { Link, useParams} from 'react-router-dom';
import axios from 'axios';
import { useStarred } from '../hooks/api.hooks';
import HomeButton from './HomeButton';

export default function Starred() {
    const {setGameState, setStarred } = useContext(QuizContext);
    const { gameId } = useParams();
    const starredQuestions = useStarred(gameId);
    if(!gameId){
        return <p> invalid url </p>;
    }
    const ListItem = (props) => {
        return (
            <li className='listItem' id={'id'+props.p.MarkedQuestion.created_at}>{props.p.Question.title}
                <button className='show-starred-optionlist' id={props.p.MarkedQuestion.id+props.p.MarkedQuestion.created_at+props.p.MarkedQuestion.question_id+'button'} onClick={() => {
                    showOptions(props.p.MarkedQuestion.id+props.p.MarkedQuestion.created_at+props.p.MarkedQuestion.question_id)
                }}>Näytä</button>
                <ul className='starred-optionlist' style={{display: "none"}} id={props.p.MarkedQuestion.id+props.p.MarkedQuestion.created_at+props.p.MarkedQuestion.question_id}>
                <li><b>A:</b> {props.p.Question.choice_options[0].answer}</li>
                <li><b>B:</b> {props.p.Question.choice_options[1].answer}</li>
                <li><b>C:</b> {props.p.Question.choice_options[2].answer}</li>
                <li><b>D:</b> {props.p.Question.choice_options[3].answer}</li>
                </ul>
                <button className='delStar' onClick={() => {
                    deleteStar(props.p);
                }}>X</button>
            </li>
        )
    }

    const deleteStar = (x) => {
        axios.delete("/questions/marked/"+x.MarkedQuestion.id).then((res) => {
            if(res.status === 200){
                const starredQs = starredQuestions.filter((p) => p.MarkedQuestion.id !== x.MarkedQuestion.id);
                setStarred(starredQs);
            }else{
                alert("Virhe tapahtui poistamisen yhteydessä, yritä myöhemmin uudelleen");
            }
        });
    }

    let starList = starredQuestions.map((starredQuestions) =>
        <ListItem p={starredQuestions} key={starredQuestions.MarkedQuestion.id+starredQuestions.MarkedQuestion.created_at.toString()}/>
    );

    const showOptions = (x) => {
        let list = document.getElementById(x);
        let btn = document.getElementById(x+'button');
        if (list.style.display === 'none') {
            list.style.display = 'block';
            btn.textContent = 'Sulje';
        } else {
            list.style.display = 'none';
            btn.textContent = 'Näytä';
        }
    }
    
    return (
        <div className='component'>
            <HomeButton gid={gameId}/>
            <Info/>
            <h3 className='header3'>Tärkeät kysymykset</h3>
            {starList.length === 0 ? (
                <p style={{padding: '20px'}}>Et ole vielä merkannut tärkeitä kysymyksiä.</p>
            ):(
                <ul className='qList'>
                    {starList}
                </ul>
            )}
            <Link to={`/game/${gameId}/settings`}>
                <button className='back' onClick={() => {
                    setGameState('settings');
                }}>Takaisin</button>
            </Link>
        </div>
    )
}