/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext} from 'react';
import { QuizContext } from '../Helpers/Contexts';
import "../App.css";

export default function Info() {
    const {gameState} = useContext(QuizContext);

    let infoText = '';
    switch (gameState) {
        case 'quiz':
            infoText = 'Painamalla plus-painiketta, voit lisätä kysymyksen tärkeiden kysymysten listaan. Voit pelata pelkkiä tärkeitä kysymyksiä valitsemalla kurssivalikosta "Tärkeät". Painamalla rastia, voit piilottaa kysymyksen, jolloin se ei enää tule seuraavissa peleissä vastaan. Voit myöhemmin poistaa kysymyksen tärkeistä kysymyksistä tai piilotettujen kysymysten listalta päävalikon "Asetukset"-kohdasta.'
            break;
        case 'course':
            infoText = 'Valitse kurssi, jonka kysymyksiä haluat harjoitella.';
            break;
        case 'gameModeMenu':
            infoText = 'Valitse pelimuoto, jota haluat pelata. Lineaarisessa pelissä voit pelata tietyn, ennalta määritellyn määrän kysymyksiä ja loputtomassa pelissä peli arpoo satunnaisia kysymyksiä, jolloin peli päättyy väärään vastaukseen.';
            break;
        case 'hidden':
            infoText = 'Voit poistaa kysymyksen listasta punaista raksia painamalla.';
            break;
        case 'starred':
            infoText = 'Voit poistaa kysymyksen listasta punaista raksia painamalla.';
            break;
        case 'settings':
            infoText = 'Täältä voit hallita piilottamiasi ja tärkeiksi merkittyjä kysymyksiäsi.';
            break;
        case 'chapter':
            infoText = 'Voit pelata koko kurssin kysymyksiä tai valita tietyn luvun.';
            break;
        case 'gameLength':
            infoText = 'Valitse kuinka monta kysymystä haluat pelata. Voit myös valita haluatko pelin näyttävän oikean vastauksen vastaamisen jälkeen.'
            break;
        default:
            break;
    }

    const showInfo = () => {
        let text = document.getElementById(gameState+'info-text');
        if (text.style.display === 'none') {
            text.style.display = 'block';
        } else {
            text.style.display = 'none';
        }
    }

    return (
        <>
            <button className='info-button' onClick={() => {
                showInfo()
            }}><h2>?</h2></button>
            <div className='info-text' id={gameState+'info-text'} style={{display: 'none'}}>
                <p>{infoText}</p>
            </div>
        </>
    )
}