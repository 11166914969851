import React, {useContext} from 'react';
import { QuizContext } from '../Helpers/Contexts';
import { GameMode, removeLastUrlSuffix } from '../Helpers/util';
import "../App.css";
import Info from './Info';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { useQuestions,useStartGameSession } from '../hooks/api.hooks';
import HomeButton from './HomeButton';

function GameLength({courseModeType,chapterModeType}) {
    const location = useLocation();
    const { setGameState, ansVisibility, setAnsVisibility, setGameLength, setQuestions } = useContext(QuizContext);
    const { courseId, chapterId, gameId } = useParams();
    const questions = useQuestions(gameId, courseId, chapterId, chapterModeType, courseModeType);
    const startGameSession = useStartGameSession(chapterModeType, courseModeType);
    const navigate = useNavigate();
    const getRandomInt = () => {
        return Math.floor(Math.random() * questions.length);
    }

    const generateList = (n) => {
        let list = []
        for (let i = 0; i < n;) {
            let r = getRandomInt();
            if (!list.includes(questions[r])) {
                list.push(questions[r]);
                i++;
            }
        }
        return list
    }

    const LengthButton = (props) => {
        return (
            <button className='menubtn' onClick={async ()=> {
                try{
                    await startGameSession();

                    setGameLength(props.n);
                    setQuestions(generateList(props.n));
                }catch{
                    alert("Jotain meni pieleen, yritä pelata peliä myöhemmin!")
                }
                setGameState('quiz');
                navigate("quiz")
            }}>{props.n} kysymystä</button>
        )
    }

    const generateLengthButtons = () => {
        const buttons = [];
        for (let i = 20; i < (questions.length > 50 ? 50 : questions.length); i += 10) {
            buttons.push(<LengthButton key={i} n={i} />);
        }
        buttons.push(<LengthButton key={questions.length} n={questions.length} />);
        return buttons;
    };

    return(
        <div className='component'>
            <HomeButton gid={gameId}/>
            <h3>Valitse pelin pituus</h3>
            <Info/>
            <label htmlFor='show-ans'>Näytä oikea vastaus vastaamisen jälkeen</label>
            <input type='checkbox' id='show-ans' onClick={() => {
                setAnsVisibility(!ansVisibility);
            }}></input>
            {questions.length < 20 ? (
                <LengthButton n={questions.length}/>
            ) : (generateLengthButtons())
            }
            <Link to={removeLastUrlSuffix(location.pathname)}>
                <button className='back' onClick={() => {
                    setGameState('gameModeMenu');
                    setAnsVisibility(false);
                }}>Takaisin</button>
            </Link>
        </div>
    );
}

export default GameLength;

GameLength.propTypes = GameMode.propTypes;

GameLength.defaultProps = GameMode.defaultProps;