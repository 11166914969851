/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext} from 'react';
import { QuizContext } from '../Helpers/Contexts';
import "../App.css";
import Info from './Info';
import { Link, useParams } from 'react-router-dom';
import HomeButton from './HomeButton';

export default function Settings() {
    const {setGameState} = useContext(QuizContext);
    const { gameId } = useParams(); 
    return (
        <div className='component'>
            <h3 className='header3'>Asetukset</h3>
            <Info/>
            <HomeButton gid={gameId}/>
            <Link to="hidden">
                <button className='menubtn' onClick={() => {
                    setGameState('hidden');
                }}>Piilotettujen kysymysten hallinta</button>
            </Link>
            <Link to="starred">
                <button className='menubtn' onClick={() => {
                    setGameState('starred');
                }}>Tärkeiden kysymysten hallinta</button>
            </Link>
            <Link to={`/game/${gameId}`}>
                <button className='back' onClick={() => {
                    setGameState('course');
                }}>Takaisin</button>
            </Link>
        </div>
    )
}