/* eslint-disable react-hooks/exhaustive-deps */
import './App.css'
import React, { useEffect, useState } from 'react'
import Settings from './Components/Settings'
import Hidden from './Components/Hidden'
import * as axios from 'axios'
import { Route, Routes } from 'react-router-dom'
import { QuizContext } from './Helpers/Contexts'
import Starred from './Components/Starred'
import GameModeMenu from './Components/GameModeMenu'
import Chapters from './Components/Chapters'
import GameLength from './Components/GameLength'
import Quiz from './Components/Quiz'
import Game from './Game'
import EndScreen from './Components/EndScreen'
import ControlPanelApp from './ControlPanel/ControlPanelApp'
import { GameMode } from './Helpers/util'

function App({ iframeFrom }) {
  if (!iframeFrom) {
    console.error(
      'No iframeRoot found, make sure to define iframeRoot environment variable when building digiquiz'
    )
  }
  const [token, setToken] = useState()
  const [connection, setIframeConnection] = useState(null)
  const [gameData, setGameData] = useState({ title: '', id: '' })
  // state for managing which data is being fetched from the api
  const [loadingState, setLoadingState] = useState({
    course: false,
    chapter: false,
    game: false,
    starred: false,
    currChapter: false,
    currCourse: false,
  })
  const [courseData, setCourseData] = useState([])
  const [chapterData, setChapterData] = useState([])
  const [currChapter, setCurrChapter] = useState()
  const [gameLength, setGameLength] = useState()
  const [gameState, setGameState] = useState('course')
  const [course, setCourse] = useState([])
  const [score, setScore] = useState(null)
  const [gameMode, setGameMode] = useState('')
  const [questions, setQuestions] = useState([])
  const [hiddenQuestions, setHiddenQuestions] = useState([])
  const [starredQuestions, setStarred] = useState([])
  const [ansVisibility, setAnsVisibility] = useState(false)
  const [gameSession, setGameSession] = useState(undefined)
  const [bool, setBool] = useState(false)
  useEffect(() => {
    const messageListener = (evt) => {
      if (iframeFrom && iframeFrom === evt.origin) {
        const { source, origin, data } = evt
        const { apiToken, maxHeight } = data
        setIframeConnection({ source, origin, maxHeight })
        axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`
        setToken(apiToken)
      }
    }
    window.addEventListener('message', messageListener, false)

    return () => {
      window.removeEventListener('message', messageListener, false)
    }
  })

  useEffect(() => {
    let resizeObserver
    if (connection && connection.source && connection.origin) {
      // create an Observer instance
      resizeObserver = new ResizeObserver((entries) => {
        return sendHeightToIframe()
      })

      // start observing a DOM node
      resizeObserver.observe(document.body)
    }
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    }
  }, [connection])

  const sendHeightToIframe = () => {
    const height = getDocHeight(document)
    // if this document(app) needs more height than the iframe's maxHeight is we need to adjust our overflow
    if (height >= parseInt(connection.maxHeight)) {
      document.body.style['overflow-y'] = 'scroll'
    } else {
      document.body.style['overflow-y'] = 'hidden'
    }
    connection.source.postMessage({ height }, connection.origin)
  }

  if (connection !== null) {
    sendHeightToIframe()
  }

  return (
    <QuizContext.Provider
      value={{
        token,
        connection,
        loadingState,
        setLoadingState,
        gameData,
        setGameData,
        courseData,
        setCourseData,
        chapterData,
        setChapterData,
        gameState,
        setGameState,
        score,
        setScore,
        course,
        setCourse,
        questions,
        setQuestions,
        gameLength,
        setGameLength,
        currChapter,
        setCurrChapter,
        gameMode,
        setGameMode,
        hiddenQuestions,
        setHiddenQuestions,
        starredQuestions,
        setStarred,
        ansVisibility,
        setAnsVisibility,
        bool,
        setBool,
        gameSession,
        setGameSession,
      }}
    >
      <Routes>
        {/* Improve route configurations */}
        <Route path="/game/:id" element={<Game />} />
        <Route
          path="/game/:gameId/chapters/:courseId"
          element={<Chapters />}
        ></Route>
        <Route
          path="/game/:gameId/chapters/all"
          element={<GameModeMenu courseModeType={GameMode.ALL.TYPE} />}
        >
          {/* TODO does GameModeMenu have the option to set chapterId? aka path
              /game/:gameId/chapters/all/:chapterId/gamelength
          */}
        </Route>
        <Route
          path="/game/:gameId/chapters/all/gamelength"
          element={
            <GameLength
              courseModeType={GameMode.ALL.TYPE}
              chapterModeType={GameMode.ALL.TYPE}
            />
          }
        ></Route>
        <Route
          path="/game/:gameId/chapters/all/quiz"
          element={
            <Quiz
              courseModeType={GameMode.ALL.TYPE}
              chapterModeType={GameMode.ALL.TYPE}
            />
          }
        ></Route>
        <Route
          path="/game/:gameId/chapters/all/quiz/result"
          element={<EndScreen />}
        ></Route>
        <Route
          path="/game/:gameId/chapters/all/gamelength/quiz"
          element={
            <Quiz
              courseModeType={GameMode.ALL.TYPE}
              chapterModeType={GameMode.ALL.TYPE}
            />
          }
        ></Route>
        <Route
          path="/game/:gameId/chapters/all/gamelength/quiz/result"
          element={<EndScreen />}
        ></Route>

        {/* CourseId routes */}
        <Route
          path="/game/:gameId/chapters/:courseId/gamemode/all"
          element={<GameModeMenu chapterModeType={GameMode.ALL.TYPE} />}
        ></Route>
        <Route
          path="/game/:gameId/chapters/:courseId/gamemode/all/gamelength"
          element={<GameLength chapterModeType={GameMode.ALL.TYPE} />}
        ></Route>
        <Route
          path="/game/:gameId/chapters/:courseId/gamemode/all/gamelength/quiz"
          element={<Quiz chapterModeType={GameMode.ALL.TYPE} />}
        ></Route>
        <Route
          path="/game/:gameId/chapters/:courseId/gamemode/all/gamelength/quiz/result"
          element={<EndScreen />}
        ></Route>
        <Route
          path="/game/:gameId/chapters/:courseId/gamemode/all/quiz"
          element={<Quiz chapterModeType={GameMode.ALL.TYPE} />}
        ></Route>
        <Route
          path="/game/:gameId/chapters/:courseId/gamemode/all/quiz/result"
          element={<EndScreen />}
        ></Route>
        {/* CourseId routes end */}

        {/* ChapterId routes */}
        <Route
          path="/game/:gameId/chapters/:courseId/gamemode/:chapterId"
          element={<GameModeMenu />}
        ></Route>
        <Route
          path="/game/:gameId/chapters/:courseId/gamemode/:chapterId/gamelength"
          element={<GameLength />}
        ></Route>
        {/* Create a path for :chapterId/quiz??? */}
        <Route
          path="/game/:gameId/chapters/:courseId/gamemode/:chapterId/gamelength/quiz"
          element={<Quiz />}
        ></Route>
        <Route
          path="/game/:gameId/chapters/:courseId/gamemode/:chapterId/gamelength/quiz/result"
          element={<EndScreen />}
        ></Route>
        <Route
          path="/game/:gameId/chapters/:courseId/gamemode/:chapterId/quiz"
          element={<Quiz />}
        ></Route>
        <Route
          path="/game/:gameId/chapters/:courseId/gamemode/:chapterId/quiz/result"
          element={<EndScreen />}
        ></Route>
        <Route
          path="/game/:gameId/chapters/:courseId/gamemode/:chapterId/gamelength/result"
          element={<EndScreen />}
        ></Route>
        {/* ChapterId routes end */}

        {/* Setting routes */}
        <Route path="/game/:gameId/settings" element={<Settings />}></Route>
        <Route
          path="/game/:gameId/settings/hidden"
          element={<Hidden />}
        ></Route>
        <Route
          path="/game/:gameId/settings/starred"
          element={<Starred />}
        ></Route>
        {/* Settings routes end */}

        {/* Starred routes */}
        <Route
          path="/game/:gameId/starred"
          element={
            <GameModeMenu
              chapterModeType={GameMode.IMPORTANT.TYPE}
              courseModeType={GameMode.IMPORTANT.TYPE}
            />
          }
        ></Route>
        <Route
          path="/game/:gameId/starred/gamelength"
          element={
            <GameLength
              chapterModeType={GameMode.IMPORTANT.TYPE}
              courseModeType={GameMode.IMPORTANT.TYPE}
            />
          }
        ></Route>
        <Route
          path="/game/:gameId/starred/gamelength/quiz"
          element={
            <Quiz
              chapterModeType={GameMode.IMPORTANT.TYPE}
              courseModeType={GameMode.IMPORTANT.TYPE}
            />
          }
        ></Route>
        <Route
          path="/game/:gameId/starred/gamelength/quiz/result"
          element={<EndScreen />}
        ></Route>
        <Route
          path="/game/:gameId/starred/quiz"
          element={
            <Quiz
              chapterModeType={GameMode.IMPORTANT.TYPE}
              courseModeType={GameMode.IMPORTANT.TYPE}
            />
          }
        ></Route>
        <Route
          path="/game/:gameId/starred/quiz/result"
          element={<EndScreen />}
        ></Route>
        {/* Starred routes end*/}

        {/* ADMIN ROUTES */}
        <Route path="/admin/controlpanel" element={<ControlPanelApp />}></Route>
        {/* ADMIN ROUTES END */}

        {/* 404 */}
        <Route path="*" element={<NotFound />}></Route>
        <Route path="/404" element={<NotFound />}></Route>
      </Routes>
    </QuizContext.Provider>
  )
}

const NotFound = () => <p> Sivua ei ole olemassa </p>

function getDocHeight(doc) {
  doc = doc || document

  let body = doc.body,
    html = doc.documentElement
  let height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  )
  return height
}

export default App
