import axios from 'axios';
import React, { useState, createContext, useContext,useEffect } from 'react';
import Modal from './Modal'
import { debounce } from '../../Helpers/util'
import PropTypes from 'prop-types';

const PAGE_RESULT_LIMIT = 100;

const apiGame = ({search=null, skip=0, limit=PAGE_RESULT_LIMIT}) => {
    let query = `/games/?skip=${skip}&limit=${limit}`;
    if(search){
        query += `&search=${search}`
    }
    return axios.get(query);
}

const GameContext = createContext();

export default function ChapterForm() {
    const [games, setGames] = useState([]);
    const [search, setSearch] = useState(null);
    const [skip, setSkip] = useState(0);
    const [alertText, setAlert] = useState(null)
    const [formVisible, setToggleForm] = useState({
        add: false,
        show: false,
    })

    const fetchGame = () => {
        return apiGame({search,skip})
            .then(res => {
                const { status, data } = res;
                if(status === 200){
                    setGames(data)
                }else{
                    throw new Error(`Unknown status code response from server: ${status}`)
                }
            }).catch(()=>alert("Jotain meni pieleen datan hakemisessa"));
    };
    // if query params change reset pagination (skip)
    useEffect(()=>{
        setSkip(0)
    },[search])

    useEffect(()=>{
        //FIXME use abort controller to handle the unmounting of this object
        fetchGame()
    },[skip,search])

    const nextPage = () => {
        if(games.length >= PAGE_RESULT_LIMIT){
            const newSkip = skip+PAGE_RESULT_LIMIT
            setSkip(newSkip);
        }
    };

    const lastPage = () => {
        if(skip > 0){   
            // skip can't be a negative value
            const newSkip = (skip-PAGE_RESULT_LIMIT) < 0 ? 0 : (skip-PAGE_RESULT_LIMIT); 
            setSkip(newSkip);
        }
    };

    return (
        <GameContext.Provider
            value={{
                games,
                fetchGame,
                setToggleForm,
                skip,
                lastPage,
                nextPage,
            }}>
            <div className='form-comp'>
                <h2 className='controlpanel-h2'>Pelin hallinta</h2>

                <div id='game-res' className='response-text' style={{display: 'none'}}></div>

                <button className='open-but' onClick={()=> {
                    setToggleForm({add: true})
                }}>Lisää Peli</button>
                {
                    formVisible.add && (
                        <AddGame />
                    )
                }
   
                <button className='open-but' onClick={()=> {
                    setToggleForm({show: true})
                }}>Hae</button>
                {
                    formVisible.show && (
                        <div id='getchapter' className='chapterform'>
                            <input 
                                type="text" 
                                className='form-child' 
                                placeholder='Hae otsikon perusteella'
                                name='search'
                                onChange={debounce(evt => setSearch(evt.target.value),300)}
                                />

                            <GameList />
                        </div>
                    )
                }
            </div>
        </GameContext.Provider>
    );
}

const GameList = () => {
    const { games, skip, lastPage, nextPage } = useContext(GameContext)


    const Pagination = () => (
        <div className='between'>
            <button 
                style={{visibility: skip > 0 ? "visible" : "hidden"}}  
                className="link-button"
                onClick={lastPage}>
                Edellinen sivu
            </button>
            <button
                style={{visibility: games.length >= PAGE_RESULT_LIMIT ? "visible" : "hidden"}}  
                className="link-button"
                onClick={nextPage}>
                Seuraava sivu
            </button>
        </div>
    );

    return (
        <ul className="list">
            <Pagination />

            {
                games.length > 0 ? games.map(game => <GameItem key={game.id + "game-item"} game={game} />) : <p> Moduuleja ei löytynyt </p>
            }

            <Pagination />
        </ul>
    )
}

const GameItem = ({game: ogCourse}) => {
    const { fetchGame } = useContext(GameContext);
    const [game, setGame] = useState(ogCourse)
    const [open, setOpen] = useState(false);

    const onDeleteClick = (id) => {
        // eslint-disable-next-line no-restricted-globals
        if(confirm("Oletko varma, että haluat poistaa moduulin?")){
            axios.delete(`/games/${id}`)
                .then(res => {
                    if(res.status === 200 ){
                        fetchGame()
                    }else{
                        throw new Error(`Unexpected status code in a delete response: ${res.status}`)
                    }
                }).catch(() => alert("Jotain meni pieleen yritä myöhemmin uudelleen!"))
        }
    }
  
    const patchGame = (game) => {
        axios.patch(`/games/${game.id}/`,game)
            .then((res) => {
                const { status, data } = res;
                if(status === 200){
                    setGame(data);
                    setOpen(false)
                }else{
                    throw new Error(`Unknown status code from server: ${status}`)
                }
            }).catch(err => {
                alert("Jotain meni pieleen yritä myöhemmin uudelleen")
            });      
    };
    
    return (
        <li className='list-child'> 
            ID: {game.id} | {game.name}
            <br />
            Pelin ID: {game.game_id}
            <div className='between'>
                <button className="link-button" onClick={() => setOpen(true)}> 
                    Muokkaa 
                </button> 
                <button 
                    className="link-button" 
                    style={{color: "red"}} 
                    onClick={() => onDeleteClick(game.id)}> 
                    Poista 
                </button>
            </div>

            <Modal open={open} toggle={() => setOpen(!open)} header="Muokkaa lukua">
                <GameField onSave={patchGame} game={game}></GameField>
            </Modal>
        </li>
    )
}

const AddGame = () => {
    const { fetchGame, setToggleForm } = useContext(GameContext)
    const postGame = game => {
        axios.post("/games/", game)
            .then(res => {
                const { status } = res;
                if(status === 200 || status === 201){
                    setToggleForm({add: false, show:true })
                    fetchGame()              
                }else{
                    throw new Error(`Unknown status code from post: ${status}`)
                }
            }).catch(() => alert("Jotain meni pieleen yritä myöhemmin uudelleen"));
    } 


    return <GameField onSave={postGame} ></GameField>
}

const newGameObj = { 
    game_id: 0, 
    name: "", 
    description: ""
};

const GameField = ({onSave, game: parentGame = newGameObj}) => {
    const [game, setGame] = useState(parentGame)

    const setData = evt => {
        const { name, value } = evt.target;
        if(Object.keys(game).includes(name)){
            setGame({
                ...game,
                [name]:  value
            })
        }else{
            throw new Error(`Input ${name} is not included in the game data`)
        }
    }

    return(
        <div id='addquestion' className='questionform'>
            <p>Tähdellä (*)-merkityt kohdat ovat pakollisia</p>
            <input 
                onChange={setData} 
                value={game.name} 
                name="name" 
                type="text" 
                className='form-child' 
                placeholder='Pelin otsikko *'/>
            <input 
                onChange={setData} 
                value={game.description} 
                name="description" 
                type="text" 
                className='form-child' 
                placeholder='Pelin leipäteksti *'/>

            <button className='sub-but' onClick={() => onSave(game)}>Tallenna</button>
        </div>
    );
};

GameField.propTypes = {
    game: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string
    }),
    onSave: PropTypes.func.isRequired
};