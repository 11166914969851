import * as PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

export const GameSessionType = { 
    GAME: "game",
    COURSE: "course",
    CHAPTER: "chapter",
    IMPORTANT: "important"
};

export class GameMode {
    static ALL = {
        TYPE: "all",
        TITLE: "Kaikki"
    };
    static IMPORTANT = {
        TYPE: "important",
        TITLE: "Tärkeät"
    };
    static propTypes = {
        // chapterModeType = Enum(GameMode.ALL.TYPE, GameMode.IMPORTANT.TYPE, null). null means that the user is playing a specific chapter
        chapterModeType: PropTypes.oneOf([GameMode.ALL.TYPE, GameMode.IMPORTANT.TYPE, null]),
        // courseModeType = Enum(GameMode.ALL.TYPE, GameMode.IMPORTANT.TYPE, null). null means that the user is playing a specific chapter
        courseModeType: PropTypes.oneOf([GameMode.ALL.TYPE, GameMode.IMPORTANT.TYPE, null]),
    };
    static defaultProps = {
        chapterModeType: null,
        courseModeType: null,
    };
}

/**
 * Removes the last suffix of a url
 * 
 * @example <caption> Example usage of removeLastSuffix </caption>
 * removeLastSuffix("/path/remove_this_suffix") === "/path";
 * removeLastSuffix("/path") === "/";
 * removeLastSuffix("/path?name='peke'") === "/";
 * 
 * @param {string} url url location pathname 
 * @returns {string}
 */
export const removeLastUrlSuffix = (url) => {
    const paths = url.split("/");
    paths.pop();
    if(paths.length > 1) {
        return paths.join("/");
    }
    return "/";
}

/**
 * Do a certain action if this 'debounce' hasn't been called in the period of the timeout
 * 
 * @param {function} func 
 * @param {number} timeout 
 * @returns 
 */
export function debounce(func, timeout = 300){
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}
/**
 * Figure out the type of game session currently in use.
 */
/**
 * 
 * @param {string} chapterModeType 
 * @param {string} courseModeType 
 * @returns 
 */
export const useGameSessionType = (chapterModeType, courseModeType) => {
    // if chapter mode type is defined it will override course mode type. Because course mode type is higher on the component hierarchy
    const gameMode = chapterModeType || courseModeType;
    const { gameId, courseId, chapterId } = useParams(); 

    if(gameMode && gameMode === GameMode.IMPORTANT.TYPE) {
        return GameSessionType.IMPORTANT 
    }
    if(chapterId !== undefined){
        return GameSessionType.CHAPTER       
    }else if (courseId !== undefined){
        return GameSessionType.COURSE
    }else if (gameId !== undefined){
        return GameSessionType.GAME        
    }else{
        return GameSessionType.IMPORTANT
    }
}