/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react'
import { QuizContext } from '../Helpers/Contexts'
import '../App.css'
import Info from './Info'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useCourseData, useGameData, useStarred } from '../hooks/api.hooks'
import { GameMode } from '../Helpers/util'
import HomeButton from './HomeButton.js'

export default function Courses() {
  const { setGameState, setCourse, setCurrChapter } = useContext(QuizContext)
  const { id: gameId } = useParams()

  const gameData = useGameData(gameId)
  const courseData = useCourseData(gameId)
  const starredQuestions = useStarred(gameId)

  const modules =
    courseData &&
    courseData.map((course) => (
      <Link
        to={`/game/${gameId}/chapters/${course.id}`}
        key={course.game_id + course.title + course.id}
      >
        <button
          className="menubtn"
          onClick={() => {
            setCourse(course)
            setGameState('chapter')
          }}
        >
          {course.title}
        </button>
      </Link>
    ))

  return (
    <div className="component">
      <HomeButton gid={gameId} />
      <div id="fb" style={{ display: 'none' }}>
        <p>
          Lisää vähintään 5 kysymystä tärkeisiin kysymyksiin, jotta voit pelata
          tärkeitä kysymyksiä.
        </p>
      </div>
      <h1>{gameData && gameData.name}</h1>
      <h2 id="ch1">Valitse peli</h2>
      <Info />
      <div id="buttonContainer">
        {courseData && courseData.length !== 1 && (
          <Link to={`/game/${gameId}/chapters/${GameMode.ALL.TYPE}`}>
            <button
              className="menubtn"
              onClick={() => {
                setCourse({ title: GameMode.ALL.TITLE })
                setCurrChapter({ title: GameMode.ALL.TITLE })
                setGameState('gameModeMenu')
              }}
            >
              Kaikki
            </button>
          </Link>
        )}
        {modules}
        {starredQuestions.length >= 5 ? (
          <Link to={`/game/${gameId}/starred`}>
            <button
              className="menubtn"
              onClick={() => {
                setCourse({ title: GameMode.IMPORTANT.TITLE })
                setCurrChapter({ title: GameMode.IMPORTANT.TITLE })
                setGameState('gameModeMenu')
              }}
            >
              Tärkeät kysymykset
            </button>
          </Link>
        ) : (
          <button
            className="menubtn"
            onClick={() => {
              let fb = document.getElementById('fb')
              fb.style.display = 'block'
              setTimeout(() => {
                fb.style.display = 'none'
              }, '5000')
            }}
          >
            Tärkeät kysymykset
          </button>
        )}
      </div>
      <Link to="settings">
        <button
          className="back"
          onClick={() => {
            setGameState('settings')
          }}
        >
          Asetukset
        </button>
      </Link>
    </div>
  )
}
