import { useState,createContext,useContext,useEffect } from 'react';
import axios from 'axios';
import Modal from './Modal'
import PropTypes from 'prop-types';
import { debounce } from '../../Helpers/util';

const PAGE_RESULT_LIMIT = 100;

const apiQuestions = ({courseId = null, chapterId = null, search=null, skip=0, limit=PAGE_RESULT_LIMIT}, abort) => {
    let query = `/questions/all?skip=${skip}&limit=${limit}`;
    if(courseId){
        query += `&course_id=${courseId}`
    }
    if(chapterId){
        query += `&chapter_id=${chapterId}`
    }
    if(search){
        query += `&search=${search}`
    }
    return axios.get(query);
}

const QuestionContext = createContext();

export default function QuestionForm() {
    const [questions, setQuestions] = useState([]);
    const [chapterId, setChapterId] = useState(null)
    const [courseId, setCourseId] = useState(null);
    const [search, setSearch] = useState(null);
    const [skip, setSkip] = useState(0);
    const [alertText, setAlert] = useState(null)
    const [formVisible, setToggleForm] = useState({
        add: false,
        show: false,
        delete: false  
    })

    // arg skip is needed because then we don't have to write useEffect hook that will trigger fetchQuestions when we want to change skip value via setSkip 
    const fetchQuestions = (argSkip = skip) => {
        apiQuestions({search,courseId,chapterId,skip: argSkip, limit: PAGE_RESULT_LIMIT}).then(res => {
            setQuestions(res.data)
        });
    }

    // if query params change reset pagination (skip)
    useEffect(()=>{
        setSkip(0)
    },[search,courseId,chapterId])

    useEffect(() => {
        // TODO implement abort controller for aborting on unmount
        fetchQuestions()
    }, [search,courseId,chapterId, skip])
    
    const nextPage = () => {
        if(questions.length >= PAGE_RESULT_LIMIT){
            const newSkip = skip+PAGE_RESULT_LIMIT
            setSkip(newSkip);
        }
    };

    const lastPage = () => {
        if(skip > 0){
            // skip can't be a negative value
            const newSkip = (skip-PAGE_RESULT_LIMIT) < 0 ? 0 : (skip-PAGE_RESULT_LIMIT); 
            setSkip(newSkip);
        }
    };

    return (
        <QuestionContext.Provider
            value={{
                nextPage,
                lastPage,
                questions,
                refresh: fetchQuestions,
                setAlert,
                skip
            }}
        >
            <div className='form-comp'>
                <h2 className='controlpanel-h2'>Kysymysten hallinta</h2>

                {/* TODO create a quick and dirty alert component */}
                <div id='question-res' className='response-text' style={{display: 'none'}}></div>

                <button className='open-but' onClick={()=> {
                    setToggleForm({add: !formVisible.add})
                }}>Lisää kysymys</button>
                {
                    formVisible.add && (
                        <AddQuestion refresh={() => {
                            setToggleForm({add: false})
                            setAlert("Kysymys lisätty onnistuneesti!")
                            fetchQuestions()
                        }}/>
                    )
                }

                <button className='open-but' onClick={()=> {
                    setToggleForm({show: !formVisible.show})
                }}>Hae kysymykset</button>
                {
                    formVisible.show && (
                        <div id='getquestion' className='questionform'>
                            <input 
                                type="number" 
                                id='qfetch-moduleid' 
                                className='form-child' 
                                placeholder='Moduulin ID *'
                                onChange={(evt) => setCourseId(evt.target.value)}
                            />
                            <input 
                                type="number" 
                                id='qfetch-chapterid' 
                                className='form-child' 
                                placeholder='Luvun ID'
                                onChange={(evt) => setChapterId(evt.target.value)}
                            />
                            <input 
                                type="text" 
                                className='form-child' 
                                placeholder='Kysymyksen otsikko'
                                onChange={debounce(evt => setSearch(evt.target.value),300)}
                            />
        
                            <QuestionList questions={questions} refresh={fetchQuestions}></QuestionList>
                        </div>
                    )
                }
            </div>
        </QuestionContext.Provider>
    );
}

const QuestionList = () => {
    const { questions, refresh, skip, lastPage, nextPage } = useContext(QuestionContext);
    const Pagination = () => (
        <div style={{display: "flex", justifyContent: "space-between"}}>
            <button 
                style={{visibility: skip > 0 ? "visible" : "hidden"}}  
                className="link-button"
                onClick={lastPage}>
                Edellinen sivu
            </button>
            <button
                style={{visibility: questions.length >= PAGE_RESULT_LIMIT ? "visible" : "hidden"}}  
                className="link-button"
                onClick={nextPage}>
                Seuraava sivu
            </button>
        </div>
    );

    return (
        // refactor id to be reusable class name 
        <ul id='questionlist'>
            <Pagination />
            {
                questions.length > 0 ?
                    questions.map((question, i) => <Question key={`${question.id}-${i}-question`} question={question} refresh={refresh} />)
                :
                    <p> Kysymyksiä ei löytynyt </p>
            }
            <Pagination />
        </ul>
    )
}

QuestionList.propTypes = {
    refresh: PropTypes.func.isRequired,
    questions: PropTypes.array.isRequired
}

const Question = ({question: ogQuestion,refresh}) => {
    const [question, setQuestion] = useState(ogQuestion);
    const [modalOpen, setOpen] = useState(false);
    const onModifyClick = () => {
        setOpen(true)
    }

    const onDeleteClick = () => {
        // eslint-disable-next-line no-restricted-globals
        if(confirm(`Oletko varma että haluat poistaa kysymyksen lopullisesti, tämä poistaa myös kysymyksen kaikki vaihtoehdot.`)){
            axios.delete(`/questions/${question.id}`)
                .then(refresh)
                .catch(() => alert("Jotain meni pieleen, yritä myöhemmin uudelleen."))
        }
    }

    const updateQuestion = (newQuestion,options) => {
        const promises = [
            axios.patch(`/questions/${newQuestion.id}/`,newQuestion),
            ...options.map((option,number) => {
                // if choice option has id we can assume that it has been created in the server 
                // if choice option does not contain id we can assume that it hasn't been created in the server
                if(option.id){
                    return axios.patch(`/choice_options/${option.id}/`,option)
                }
                return axios.post("/choice_options/",option).then(res => {
                    const { data, status } = res; 
                    if(status === 200 || status === 201){
                        options[number] = data
                        return options
                    }
                    return Promise.reject("Server returned malformed response")
                });
            })
        ];

        return Promise.all(promises).then(() => {
            setOpen(false);
            newQuestion["choice_options"] = options;
            setQuestion(newQuestion);
            refresh();
        }).catch(()=>alert("Jotain meni päivityksessä pieleen yritä myöhemmin uudelleen!"));
    }

    return <li className='list-child'>
        ID: {question.id} | {question.title} 
        <ul>
            {question.choice_options && question.choice_options.map((opt,i)=> {
                return <li key={opt.id+"opt-tit"} id={opt.id} className='list-opts'> Vaihtoehto {i+1}: {opt.answer} </li>
            })}
        </ul>
        <div style={{display: "flex", justifyContent: "space-between", padding: "0.5rem"}}>
            <button className="link-button" onClick={onModifyClick}> 
                Muokkaa 
            </button> 
            <button className="link-button" style={{color: "red"}} onClick={onDeleteClick}> 
                Poista 
            </button>
        </div>
        <Modal open={modalOpen} toggle={() => setOpen(!modalOpen)} header="Muokkaa kysymystä">
            <QuestionField question={question} onSave={updateQuestion} isNew={false}/>
        </Modal>
    </li> 
}

Question.propTypes = {
    question: PropTypes.object.isRequired,
    refresh: PropTypes.func.isRequired,
}

const AddQuestion = ({refresh: refreshParent}) => {
    const saveQuestion = (question, options) => {
        if(question.title && question.chapter_id){
            // FIXME validate choice options before posting anything
            // first we have to save the question and then save all the options, we could improve the performance of this function by implementing a bulk insert to the api. but currently it isn't supported
            axios.post("/questions/",question).then((res)=>{
                const { data, status } = res;
                if(status === 201 || status === 200){
                    const promises = options.map(option => axios.post("/choice_options/",{
                        ...option,
                        question_id: data.id
                    }))

                    return Promise.all(promises)
                        .then(() => refreshParent())
                        .catch((err) => alert("Jotain meni pieleen yritä myöhemmin uudelleen"));
                }
            });
        }else{
            alert("Varmista, että olet syöttänyt otsikon sekä kappaleen otsikon ennen kuin tallenta kysymyksen.")
        }
    };

    return <QuestionField onSave={saveQuestion} /> 
};

const newQuestionObj = { 
    chapter_id: 0, 
    title: "", 
    type: "choice",
    // generate list of 4 choice_option objects with only variable being correct = false
    choice_options: [...Array(4).keys()].map(()=>({
        correct: false
    }))
};

const QuestionField = ({onSave, question: parentQuestion = newQuestionObj, isNew = true}) => {
    const [question, setQuestion] = useState(parentQuestion)
    const [options, setOptions] = useState(parentQuestion.choice_options)

    const setData = evt => {
        const { name, value } = evt.target;
        if(Object.keys(question).includes(name)){
            setQuestion({
                ...question,
                [name]:  value
            })
        }else{
            throw new Error(`Input ${name} is not included in the question data`)
        }
    }

    const onDeleteChoiceOption = arrIndex => {
        if(options[arrIndex]){
            if(!isNew && options[arrIndex].id){
                axios.delete(`/choice_options/${options[arrIndex].id}`)
                    .then(res => { 
                        if(res.status === 200){
                            return true;
                        }
                        return Promise.reject("Server returned malformed response")
                    }).then(()=>{
                        const newOptions = options.filter((_,i)=>i !== arrIndex);
                        setOptions(newOptions)
                    })
                    .catch(()=>{
                        alert("Jotain meni pieleen yritä myöhemmin uudelleen!")
                    });
            }else{
                const newOptions = options.filter((_,i)=>i !== arrIndex);
                setOptions(newOptions)
            }
        }
    };
    
    const onNewOption = evt => {
        const newObj = { 
            correct: false
        }
        if(question.id){
            newObj["question_id"] = question.id
        }

        setOptions([
            ...options,
            newObj
        ]);
    };

    return(
        <div id='addquestion' className='questionform'>
            <p>Tähdellä (*)-merkityt kohdat ovat pakollisia</p>
            <input 
                onChange={setData} 
                value={question.title} 
                name="title" 
                type="text" 
                className='form-child' 
                placeholder='Kysymys *'/>
            <label>
                Luvun ID
            </label>    
            <input 
                onChange={setData}  
                // inline magic to display the placeholder when chapter_id hasn't been properly defined
                {...(question.chapter_id !== 0 && {value: question.chapter_id})}     
                name="chapter_id" 
                type="number" 
                className='form-child' 
                placeholder='Luvun ID *'/>
            {
                options.map((option,number) => {
                    return(
                        <div key={option.id||number+"opt"} style={{padding: "0.5rem"}}>
                            <input type="text" onChange={evt => {
                                options[number] = {
                                    ...options[number],
                                    answer: evt.target.value 
                                }
                                setOptions(options)
                            }} placeholder={`Vaihtoehdon ${1+number} otsikko`} defaultValue={options[number].answer} />
                            <label htmlFor={number+"chkbox"}> Oikea vastaus: {option.correct}</label>
                            <input type="checkbox" id={number+"chkbox"} onChange={evt => {
                                options[number] = {
                                    ...options[number],
                                    correct: evt.target.checked
                                }
                                setOptions(options)
                            }} defaultChecked={option.correct} />
                            <button 
                                className='link-button'
                                style={{paddingLeft: '15px', color: "red"}}
                                onClick={() => onDeleteChoiceOption(number)}>
                                Poista
                            </button>
                        </div>
                    );
                })
            }
            <button 
                className='sub-but' 
                style={{padding: "15px"}} 
                onClick={onNewOption}>
                Lisää vaihtoehto
            </button>

            <button 
                className='sub-but' 
                style={{padding: "15px"}} 
                onClick={() => onSave(question, options)}>
                Tallenna    
            </button>
        </div>
    );
};

QuestionField.propTypes = {
    question: PropTypes.shape({
        title: PropTypes.string.isRequired,
        chapter_id: PropTypes.number.isRequired,
        choice_options: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            correct: PropTypes.bool,
        })).isRequired
    }),
    onSave: PropTypes.func.isRequired,
    // is the user adding a new question object to the server
    isNew: PropTypes.bool,
};