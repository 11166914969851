import { PropTypes } from 'prop-types';

export default function Modal({open, toggle, children, header}) {
    return <div style={{display: open ? "flex" : "none"}} className="modal">
        <div className="modal-content">
            <div className='modal-header'>
                <h3 style={{marginTop: "auto", marginBottom: "auto"}}>
                    {header}
                </h3>
                <button className='menubtn' onClick={toggle}>
                    Sulje
                </button>
            </div>
            <div className='modal-body'>
                {children}
            </div>
        </div>
    </div>;
}

Modal.propTypes = {
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    header: PropTypes.string.isRequired
}