import "../App.css";
import { Link } from 'react-router-dom';
import home from "../Pictures/home_button.png";

export default function HomeButton(props) {
    return (
        <Link to={`/game/${props.gid}`}>
             <button className="homeButton">
                <img className="home-icon" src={home}></img>
            </button>
        </Link>
    )
}