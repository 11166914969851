/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react'
import { GameMode } from '../Helpers/util'
import { QuizContext } from '../Helpers/Contexts'
import Info from './Info'
import { isEmpty, fetchQuestions, useEndGameSession } from '../hooks/api.hooks'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'
import axios from 'axios'

function Quiz({ chapterModeType, courseModeType }) {
  const navigate = useNavigate()
  const location = useLocation()

  const {
    gameSession,
    setScore: setContextScore,
    setGameState,
    gameMode,
    setGameMode,
    starredQuestions,
    setStarred,
    hiddenQuestions,
    setHiddenQuestions,
    setAnsVisibility,
    ansVisibility,
    questions,
    setGameLength,
    token,
    setBool,
    setQuestions,
    loadingState,
    setLoadingState,
    setGameSession,
  } = useContext(QuizContext)
  // seperated state score and context score so that we can be sure that the score starts from zero when arriving to a new quiz
  const [score, setStateScore] = useState(0)
  // current question index, it alsos shows how many questions the user has answered.
  const [currQuestion, setCurrQuestion] = useState(0)
  const [optionChosen, setOptionChosen] = useState('')
  const [isPosting, setPosting] = useState(false)
  const [hasPosted, setPosted] = useState(false)
  const [postSuccessMessage, setPostSuccessMessage] = useState('')
  const [locked, setLocked] = useState(false)

  const endGameSession = useEndGameSession()
  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max)
  }
  const { courseId, gameId, chapterId } = useParams()

  const getHiddenQuestions = () => {
    axios
      .get('/questions/marked?skip=0&limit=100&type=excluded&game_id=' + gameId)
      .then((res) => {
        const { data } = res
        setHiddenQuestions(data)
      })
  }

  const getStarredQuestions = () => {
    axios
      .get(
        '/questions/marked?skip=0&limit=100&type=important&game_id=' + gameId
      )
      .then((res) => {
        const { data } = res
        setStarred(data)
      })
  }

  useEffect(() => {
    getStarredQuestions()
    getHiddenQuestions()
  }, [])

  const [random, setRandom] = useState(getRandomInt(questions.length))
  // Set redirect urls
  let invalidStateUrl, stopGameUrl
  if (courseId && chapterId) {
    invalidStateUrl = `/game/${gameId}/chapters/${courseId}/gamemode/${chapterId}/gamelength`
    stopGameUrl = `/game/${gameId}/chapters/${courseId}`
  } else if (courseId && !chapterId) {
    invalidStateUrl = `/game/${gameId}/chapters/${courseId}`
    stopGameUrl = `/game/${gameId}`
  } else {
    invalidStateUrl = `/game/${gameId}`
    stopGameUrl = `/game/${gameId}`
  }

  // game session must be iniated before a player can play a game. We need this because we want to gather data about the usage of games in general.
  if (gameSession === undefined) {
    navigate(invalidStateUrl)
  }

  useEffect(() => {
    // if there is no questions make the user to go back to the gamelength page
    if (isEmpty(questions) && location.pathname.includes('gamelength')) {
      navigate(invalidStateUrl)
      return null
    } else if (token && isEmpty(questions) && !loadingState.questions) {
      const hiddenIds = isEmpty(hiddenQuestions)
        ? []
        : hiddenQuestions.map((obj) => obj?.Question?.id)
      setLoadingState({ ...loadingState, questions: true })
      fetchQuestions(
        gameId,
        courseId,
        chapterId,
        chapterModeType,
        courseModeType,
        hiddenIds
      )
        .then((data) => setQuestions(data))
        .finally(() => setLoadingState({ ...loadingState, questions: false }))
    }
  }, [questions, token])

  const postChoosenOption = async () => {
    if (optionChosen !== '') {
      // because optionChosen has been declared we can conclude that there is a answer to the question
      return await axios
        .post(`/choice_options/${optionChosen}/check/${gameSession.id}/`)
        .then((res) => {
          const { data } = res
          setBool(data)
          return data
        })
    } else {
      // because optionChosen has not been declared we can conclude that the user wishes to skip the current question
      await axios.post(
        `/questions/${questions[currQuestion].id}/skip/${gameSession.id}/`
      )
      return undefined
    }
  }

  const nextQ = async () => {
    setPosted(false)
    setPosting(false)
    if (!locked) {
      const correct = await postChoosenOption()
      if (gameMode === 'linear') {
        if (optionChosen !== '') {
          if (correct) {
            setStateScore(score + 2)
          } else {
            setStateScore(score - 1)
          }
        }
      } else {
        if (optionChosen === '') {
          return
        }
        if (!correct) {
          finishQuiz()
          return
        } else {
          setStateScore(score + 1)
        }
      }
    } else {
      setLocked(false)
    }
    if (gameMode === 'linear') {
      setCurrQuestion(currQuestion + 1)
      setOptionChosen('')
    } else {
      setCurrQuestion(currQuestion + 1)
      setOptionChosen('')
      setRandom(getRandomInt(questions.length))
    }
  }

  const calculate_end_score = async (optionChosen, correct) => {
    let newScore = score

    if (gameMode === 'linear') {
      if (optionChosen !== '') {
        if (correct) {
          return (newScore += 2)
        } else {
          return --newScore
        }
      }
    } else {
      if (optionChosen !== '') {
        if (correct) {
          return ++newScore
        }
      }
    }

    return newScore
  }

  const finishQuiz = async () => {

    let end_score = score
    
    if (!ansVisibility) {
      const correct = await postChoosenOption()
      end_score = await calculate_end_score(optionChosen, correct)
    }


    setAnsVisibility(false)
    setGameState('endScreen')
    // when the user finishes the quiz we want to set the score to the context of the application so that we can use it in EndScreen
    setContextScore(end_score)
    endGameSession(
      typeof end_score === 'number' ? end_score : 0,
      questions.length
    )
    navigate('result')
  }

  const handleClick = (optID) => {
    if (!locked) {
      if (optionChosen === optID) {
        setOptionChosen('')
        return
      }
      setOptionChosen(optID)
    }
  }
  const stopGame = () => {
    setGameMode('')
    // because score can be null we have to check that we are giving a
    endGameSession(typeof score === 'number' ? score : 0, currQuestion)
    
    setGameLength(0)
    setStateScore(0)
    setAnsVisibility(false)
    setGameSession(undefined)
    setGameState('gameModeMenu')
  }

  const addToStarred = (x) => {
    if (!isPosting || !hasPosted) {
      setPosting(true)
      axios
        .post('/questions/marked/', {
          question_id: x.id,
          type: 'important',
        })
        .then(function (response) {
          if (response.status === 200) {
            setPosted(true)
            setPostSuccessMessage('Kysymys lisätty tärkeisiin!')
            setStarred([
              ...starredQuestions,
              { Question: x, MarkedQuestion: response.data },
            ])
          } else {
            return Promise.reject()
          }
        })
        .catch(function (error) {
          setPosted(false)
          alert('Tilapäinen virhe, yritä myöhemmin uudestaan!')
        })
        .finally(() => setPosting(false))
    }
  }

  const hideQ = (x) => {
    if (!isPosting || !hasPosted) {
      setPosting(true)

      axios
        .post('/questions/marked/', {
          question_id: x.id,
          type: 'excluded',
        })
        .then(function (response) {
          if (response.status === 200) {
            setPosted(true)
            setPostSuccessMessage('Kysymys piilotettu!')
            setHiddenQuestions([
              ...hiddenQuestions,
              { Question: x, MarkedQuestion: response.data },
            ])
          } else {
            return Promise.reject()
          }
        })
        .catch(function (error) {
          setPosted(false)
          alert('Tilapäinen virhe, yritä myöhemmin uudestaan!')
        })
        .finally(() => setPosting(false))
    }
  }

  const checkCorrect = (id) => {
    axios.get(`/choice_options/${id}/check/${gameSession.id}`).then((res) => {
      const { data } = res
      if (data) {
        for (let i = 0; i < btns.length; i++) {
          if (btns[i].value.toString() === id.toString()) {
            btns[i].style.backgroundColor = 'green'
            break
          }
        }
      }
    })
  }

  const getCorrect = async () => {
    let checkBtn = document.getElementById('check')
    if (
      checkBtn.textContent === 'Tulokset' &&
      questions[currQuestion] === questions[questions.length - 1]
    ) {
      finishQuiz()
    } else if (checkBtn.textContent === 'Tarkista') {
      await setLocked(true)
      const correct = await postChoosenOption()
      if (gameMode === 'linear') {
        if (optionChosen !== '') {
          if (correct) {
            setStateScore(score + 2)
          } else {
            setStateScore(score - 1)
          }
        }
      } else {
        if (optionChosen === '') {
          return
        }
        if (!correct) {
          finishQuiz()
          return
        } else {
          setStateScore(score + 1)
        }
      }

      for (let i = 0; i < btns.length; i++) {
        await checkCorrect(questions[currQuestion].choice_options[i].id)
      }
      if (questions[currQuestion] === questions[questions.length - 1]) {
        checkBtn.textContent = 'Tulokset'
      } else {
        checkBtn.textContent = 'Seuraava'
      }
    } else if (checkBtn.textContent === 'Seuraava') {
      nextQ()
      checkBtn.textContent = 'Tarkista'
    }
  }

  let btns = document.getElementsByClassName('opt')
  if (isEmpty(questions)) {
    return <div>Loading...</div>
  } else {
    return (
      <div className="Quiz">
        <div className="options">
          {gameMode === 'linear' ? (
            <>
              <p>{currQuestion + 1 + '/' + questions.length}</p>
              <h3 id="question">{questions[currQuestion].title}</h3>
              {!hasPosted ? (
                <div className="icons">
                  <button
                    className="icon"
                    id="plus"
                    disabled={false}
                    onClick={() => addToStarred(questions[currQuestion])}
                  >
                    +
                  </button>
                  <button
                    className="icon"
                    id="cross"
                    disabled={false}
                    onClick={() => hideQ(questions[currQuestion])}
                  >
                    X
                  </button>
                  <Info />
                </div>
              ) : (
                <p style={{ color: 'green' }}>{postSuccessMessage}</p>
              )}
              <span id="feedback"></span>
              {questions[currQuestion].choice_options.map(({ answer, id }) => (
                <button
                  className="opt"
                  id={id}
                  key={id}
                  value={id}
                  style={{
                    background: id === optionChosen ? 'gray' : 'lightgray',
                  }}
                  onClick={() => handleClick(id)}
                >
                  {answer}
                </button>
              ))}
            </>
          ) : (
            <>
              <p>{'Kysymys ' + (currQuestion + 1)}</p>
              <h3 id="question">{questions[random].title}</h3>
              {!hasPosted ? (
                <div className="icons">
                  <button
                    className="icon"
                    id="plus"
                    disabled={false}
                    onClick={() => addToStarred(questions[random])}
                  >
                    +
                  </button>
                  <button
                    className="icon"
                    id="cross"
                    disabled={false}
                    onClick={() => hideQ(questions[random])}
                  >
                    X
                  </button>
                  <Info />
                </div>
              ) : (
                <p style={{ color: 'green' }}>{postSuccessMessage}</p>
              )}
              <span id="feedback"></span>
              {questions[random].choice_options.map(({ answer, id }) => (
                <button
                  className="opt"
                  id={id}
                  key={id}
                  value={id}
                  style={{
                    background: id === optionChosen ? 'gray' : 'lightgray',
                  }}
                  onClick={() => handleClick(id)}
                >
                  {answer}
                </button>
              ))}
            </>
          )}
          {currQuestion === questions.length - 1 &&
          gameMode === 'linear' &&
          ansVisibility === false ? (
            <Link to="result">
              <button
                className="quizbtn"
                id="finish"
                onClick={() => finishQuiz()}
              >
                Lopeta koe
              </button>
            </Link>
          ) : (
            <>
              {ansVisibility === true ? (
                <button
                  className="quizbtn"
                  id="check"
                  onClick={() => getCorrect()}
                >
                  Tarkista
                </button>
              ) : (
                <>
                  {optionChosen === '' && gameMode === 'linear' ? (
                    <button
                      className="quizbtn"
                      id="next"
                      onClick={() => nextQ()}
                    >
                      Ohita
                    </button>
                  ) : (
                    <button
                      className="quizbtn"
                      id="next"
                      onClick={() => nextQ()}
                    >
                      Seuraava
                    </button>
                  )}
                </>
              )}
              <Link to={stopGameUrl}>
                <button
                  className="quizbtn"
                  id="stop"
                  onClick={() => {
                    stopGame()
                  }}
                >
                  Lopeta
                </button>
              </Link>
            </>
          )}
        </div>
      </div>
    )
  }
}

export default Quiz

Quiz.propTypes = GameMode.propTypes
Quiz.defaultProps = GameMode.defaultProps
