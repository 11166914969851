/* eslint-disable react-hooks/exhaustive-deps */
import * as axios from 'axios'
import { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { QuizContext } from '../Helpers/Contexts'
import { GameMode, useGameSessionType } from '../Helpers/util'

// TODO catch every failure of fetch request

/**
 * Fetches game data only when its needed. Eg when the game data has not been set or the game id has changed
 * @param {int} gameId
 * @return {any}
 */
export function useGameData(gameId) {
  const navigate = useNavigate()
  const { token, setGameData, gameData, loadingState, setLoadingState } =
    useContext(QuizContext)

  const fetchDataWrapper = () => {
    // FIXME Error handling?
    setLoadingState({ ...loadingState, game: true })
    return fetchGameData(gameId)
      .then((data) => setGameData(data))
      .finally(() => setLoadingState({ ...loadingState, game: false }))
  }

  useEffect(() => {
    if (token) {
      // Fetch game data if needed
      if (gameData && !loadingState.game) {
        if (gameData.id !== gameId) {
          fetchDataWrapper(gameId).catch(() => navigate('/404/'))
        }
      } else {
        fetchDataWrapper(gameId).catch(() => navigate('/404/'))
      }
    }
  }, [token, setGameData, gameId])

  return gameData
}
/**
 * Fetch course data when its needed. Eg when the course data has not been set or the game id has changed
 * @param {number} gameId
 * @return {any}
 */
export function useCourseData(gameId) {
  const { token, setCourseData, courseData, loadingState, setLoadingState } =
    useContext(QuizContext)

  useEffect(() => {
    if (token) {
      // Fetch course data if needed
      if (isEmpty(courseData) && !loadingState.course) {
        // FIXME Error handling?
        setLoadingState({ ...loadingState, course: true })
        fetchCourseData(gameId)
          .then((data) => setCourseData(data))
          .finally(() => setLoadingState({ ...loadingState, course: false }))
      }
    }
  }, [token, setCourseData, gameId])

  return courseData
}

export const useStarred = (gameId) => {
  const { token, starredQuestions, setStarred, loadingState, setLoadingState } =
    useContext(QuizContext)
  useEffect(() => {
    if (token) {
      if (!loadingState.starred) {
        // always fetch from the api
        setLoadingState({ ...loadingState, starred: true })
        apiFetch(
          '/questions/marked?skip=0&limit=100&type=important&game_id=' + gameId
        )
          .then((data) => setStarred(data))
          .finally(() => setLoadingState({ ...loadingState, starred: false }))
      }
    }
  }, [token, gameId, setStarred])

  return starredQuestions
}
/**
 *
 * @param {number} chapterId
 * @returns
 */
export const useCurrChapter = (chapterId, gameModeType) => {
  const { token, currChapter, setCurrChapter, setLoadingState, loadingState } =
    useContext(QuizContext)

  const fetchCurrentChapterIfNeeded = (chapterId) => {
    if (!currChapter && !loadingState.currChapter) {
      // if chapterId is not defined use ALL chapter questions or IMPORTANT questions
      // because of that we have to do this funky little unclean hack
      if (!chapterId) {
        switch (gameModeType) {
          case GameMode.ALL.TYPE:
            setCurrChapter({ id: GameMode.ALL.TYPE, title: GameMode.ALL.TITLE })
            break
          default:
            setCurrChapter({
              id: GameMode.IMPORTANT.TYPE,
              title: GameMode.IMPORTANT.TITLE,
            })
            break
        }
      } else {
        setLoadingState({ ...loadingState, currChapter: true })
        apiFetch('/chapters/' + chapterId)
          .then((data) => setCurrChapter(data))
          .finally(() =>
            setLoadingState({ ...loadingState, currChapter: false })
          )
      }
    }
  }

  useEffect(() => {
    if (token) {
      fetchCurrentChapterIfNeeded(chapterId)
    }
  }, [token, chapterId, setCurrChapter])

  return currChapter
}
/**
 *
 * @param {number} courseId
 * @returns
 */
export const useCurrCourse = (courseId, gameModeType) => {
  const { token, course, setCourse, setLoadingState, loadingState } =
    useContext(QuizContext)

  const fetchCurrentCourseIfNeeded = (courseId) => {
    if (!course && !loadingState.currCourse) {
      // if chapterId is not defined use ALL chapter questions or IMPORTANT questions
      // because of that we have to do this funky little unclean hack
      if (!courseId) {
        switch (gameModeType) {
          case GameMode.ALL.TYPE:
            setCourse({ id: GameMode.ALL.TYPE, title: GameMode.ALL.TITLE })
            break
          default:
            setCourse({
              id: GameMode.IMPORTANT.TYPE,
              title: GameMode.IMPORTANT.TITLE,
            })
            break
        }
      } else {
        setLoadingState({ ...loadingState, currCourse: true })
        apiFetch('/courses/' + courseId)
          .then((data) => setCourse(data))
          .finally(() =>
            setLoadingState({ ...loadingState, currCourse: false })
          )
      }
    }
  }

  useEffect(() => {
    if (token) {
      fetchCurrentCourseIfNeeded(courseId)
    }
  }, [token, courseId, setCourse])

  return course
}

export const useChapterData = (courseId) => {
  const { token, chapterData, setChapterData, loadingState, setLoadingState } =
    useContext(QuizContext)
  useEffect(() => {
    if (token) {
      if (!loadingState.chapter) {
        setLoadingState({ ...loadingState, chapter: true })
        apiFetch('/chapters/?course_id=' + courseId)
          .then((data) => setChapterData(data))
          .finally(() => setLoadingState({ ...loadingState, chapter: false }))
      }
    }
  }, [token, courseId, setChapterData])

  return chapterData
}

export const useHiddenQuestions = (gameId) => {
  const {
    token,
    hiddenQuestions,
    setHiddenQuestions,
    loadingState,
    setLoadingState,
  } = useContext(QuizContext)
  useEffect(() => {
    if (token && !loadingState.hidden && gameId) {
      // always fetch hidden questions
      setLoadingState({ ...loadingState, hidden: true })
      apiFetch('/questions/marked?type=excluded&game_id=' + gameId)
        .then((data) => setHiddenQuestions(data))
        .finally(() => setLoadingState({ ...loadingState, hidden: false }))
    }
  }, [token, gameId])

  return hiddenQuestions
}
// use & fetch hidden questions if needed
export const useHiddenQuestionsIfNeeded = (gameId) => {
  const {
    token,
    hiddenQuestions,
    setHiddenQuestions,
    loadingState,
    setLoadingState,
  } = useContext(QuizContext)
  useEffect(() => {
    if (token && !loadingState.hidden && isEmpty(hiddenQuestions)) {
      // always fetch hidden questions
      setLoadingState({ ...loadingState, hidden: true })
      apiFetch('/questions/marked?type=excluded&game_id=' + gameId)
        .then((data) => setHiddenQuestions(data))
        .finally(() => setLoadingState({ ...loadingState, hidden: false }))
    }
  }, [token, gameId])

  return hiddenQuestions
}
/**
 * Fetches all questions for the game state
 *
 * @param {int} gameId
 * @param {int} courseId
 * @param {int} chapterId
 * @param {GameMode.ALL.TYPE|GameMode.IMPORTANT.TYPE|null} chapterModeType if chatperId is not defined this should be defiend and vice versa
 * @param {GameMode.ALL.TYPE|GameMode.IMPORTANT.TYPE|null} courseModeType  if courseId is not defined this should be defiend and vice versa
 * @returns
 */
export const useQuestions = (
  gameId,
  courseId,
  chapterId,
  chapterModeType = null,
  courseModeType = null
) => {
  const { token, questions, setQuestions, loadingState, setLoadingState } =
    useContext(QuizContext)
  const hiddenQuestions = useHiddenQuestionsIfNeeded(gameId) // this could lead to problems, not sure how the hiding works
  const currChapter = useCurrChapter(chapterId, chapterModeType)
  useEffect(() => {
    if (currChapter && token && !loadingState.questions && hiddenQuestions) {
      const hiddenIds = isEmpty(hiddenQuestions)
        ? []
        : hiddenQuestions.map((obj) => obj?.Question?.id)
      setLoadingState({ ...loadingState, questions: true })
      fetchQuestions(
        gameId,
        courseId,
        chapterId,
        chapterModeType,
        courseModeType,
        hiddenIds
      )
        .then((data) => setQuestions(data))
        .finally(() => setLoadingState({ ...loadingState, questions: false }))
    }
  }, [
    token,
    currChapter,
    chapterId,
    gameId,
    courseId,
    setQuestions,
    hiddenQuestions,
    chapterModeType,
    courseModeType,
  ])

  return questions
}

export const fetchQuestions = (
  gameId,
  courseId,
  chapterId,
  chapterModeType = null,
  courseModeType = null,
  hiddenIds = []
) => {
  const questionList = []
  if (chapterModeType && courseModeType) {
    // they should be the same value when both of them are defined, because we cannot know
    // what courseId|chapterId to use to fetch important questions
    switch (chapterModeType) {
      case GameMode.IMPORTANT.TYPE:
        return apiFetch(
          '/questions/marked?type=important&game_id=' + gameId
        ).then((data) => {
          for (let i = 0; i < data.length; i++) {
            if (!hiddenIds.includes(data[i].id)) {
              questionList.push(data[i].Question)
            }
          }
          return questionList
        })
      // case GameMode.ALL.TYPE:
      default:
        return apiFetch('/questions/?game_id=' + gameId).then((data) => {
          for (let i = 0; i < data.length; i++) {
            if (!hiddenIds.includes(data[i].id)) {
              questionList.push(data[i])
            }
          }
          return questionList
        })
    }
  } else if (chapterModeType && !courseModeType && courseId) {
    // fetch all questions from chapter && game
    // this fetch should be executed when in urls
    // /game/:gameId/chapters/:courseId/all/gamelength
    // /game/:gameId/chapters/:courseId/all/gamelength/quiz
    switch (chapterModeType) {
      case GameMode.IMPORTANT.TYPE:
        return apiFetch(
          '/questions/marked?type=important&game_id=' +
            gameId +
            '&course_id=' +
            courseId
        ).then((data) => {
          for (let i = 0; i < data.length; i++) {
            if (!hiddenIds.includes(data[i].id)) {
              questionList.push(data[i].Question)
            }
          }
          return questionList
        })
      // case GameMode.ALL.TYPE:
      default:
        return apiFetch(
          '/questions/?game_id=' + gameId + '&course_id=' + courseId
        ).then((data) => {
          for (let i = 0; i < data.length; i++) {
            if (!hiddenIds.includes(data[i].id)) {
              questionList.push(data[i])
            }
          }
          return questionList
        })
    }
  } else if (courseModeType && !chapterModeType) {
    /* TODO does GameModeMenu have the option to set chapterId? aka path
            /game/:gameId/chapters/all/:chapterId/gamelength
        */
    return Promise.reject('This should be implemented')
  } else if (chapterId && courseId && gameId) {
    return apiFetch(
      '/questions/?course_id=' +
        courseId +
        '&chapter_id=' +
        chapterId +
        '&game_id=' +
        gameId
    ).then((data) => {
      for (let i = 0; i < data.length; i++) {
        if (!hiddenIds.includes(data[i].id)) {
          questionList.push(data[i])
        }
      }
      return questionList
    })
  } else {
    return Promise.reject('Invalid parameters given to fetchQuestions')
  }
}
/**
 *
 * @param {string} chapterModeType
 * @param {string} courseModeType
 * @returns
 */
export const useStartGameSession = (chapterModeType, courseModeType) => {
  const { setGameSession } = useContext(QuizContext)
  const type = useGameSessionType(chapterModeType, courseModeType)
  if (type === undefined)
    throw new Error(
      "game session type couldn't be determined: type cannot be null"
    )
  const { gameId, courseId, chapterId } = useParams()

  return async () => {
    const session = { type }

    if (gameId !== undefined) {
      session['game_id'] = gameId
    }
    if (chapterId !== undefined) {
      session['chapter_id'] = chapterId
    }
    if (courseId !== undefined) {
      session['course_id'] = courseId
    }

    return axios.post('/game_sessions/start/', session).then((res) => {
      if (res.status === 200 || res.status === 201) {
        setGameSession(res.data)
        return
      } else {
        throw new Error('invalid response from the api')
      }
    })
  }
}

export const useEndGameSession = () => {
  const { setGameSession, gameSession } = useContext(QuizContext)
  return async (score, questions_length) => {
    if (typeof score !== 'number' || typeof questions_length !== 'number') {
      throw new Error(
        'Variables score and questions_length are required to be numbers when calling this function. These variables are necessary to conclude the game session.'
      )
    }

    return axios
      .post(`/game_sessions/end/${gameSession.id}`, {
        score,
        questions_length,
      })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
        } else {
          throw new Error('invalid response from the api')
        }
      })
  }
}

/**
 *
 * @param {number} gameId
 * @returns {Promise<any>}
 */
const fetchCourseData = (gameId) => apiFetch('/courses/?game_id=' + gameId)

/**
 *
 * @param {int} id
 * @return {Promise<any>}
 */
const fetchGameData = (id) => apiFetch('/games/' + id)

/**
 * @param {string} url
 * @return {Promise<any>}
 */
export const apiFetch = (url) =>
  axios.get(url).then((res) => res.status === 200 && res.data)

export const isEmpty = (arr) => arr && arr instanceof Array && arr.length === 0
